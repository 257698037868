@import 'assets/styles/spacing.css';

.App {
  text-align: center;
  overflow-x: hidden;

  &--container {
    padding-top: 100px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Consolas', sans-serif;
}
