.ContactUs {
  min-height: 67vh;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EBF4F5;
}
.ContactUs .form {
  margin: 20px;
}
.ContactUs .form-title {
  margin: 0 5px;
}
.ContactUs .form__group {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 10px 0;
  padding: 15px;
  width: 500px;
}
.ContactUs .form__group .field:active, .ContactUs .form__group .field:focus, .ContactUs .form__group .field:focus-visible {
  outline: none;
  border-bottom: 1px solid #3D8361;
}
.ContactUs .form__group .field.textarea {
  resize: none;
}
.ContactUs .form__group .form-control {
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  border-bottom: 1px solid #AED2D8;
}
.ContactUs .form__group .form-control.is-invalid {
  border-bottom: 1px solid red;
}
.ContactUs .form__group .text-muted {
  display: block;
}
.ContactUs .form__group .invalid-feedback {
  position: absolute;
  color: red;
  top: 55px;
  left: 25px;
  font: 400 12px/18px Consolas;
}
.ContactUs .form__group .invalid-feedback.textarea-feedback {
  top: 105px;
}
.ContactUs .form .submit-btn {
  width: 100px;
  height: 45px;
  padding: 10px 15px;
  border-radius: 14px;
  cursor: pointer;
  font: 500 14px/21px Consolas;
}

.modal {
  padding: 30px;
  border-radius: 18px;
}
.modal-header {
  display: flex;
  font-size: 20px;
}
.modal-title {
  margin: auto;
}
.modal-body {
  margin: 15px 0;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.modal-footer .btn {
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 8px;
}

@media screen and (max-width: 960px) and (orientation: portrait) {
  .ContactUs .form {
    margin: 20px;
  }
  .ContactUs .form-title {
    width: 90%;
  }
  .ContactUs .form__group {
    width: 300px;
  }
}

