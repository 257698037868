.ContentCarousel {
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin: 20px 0;
}
.ContentCarousel img {
  height: auto;
  max-height: initial;
  width: auto;
  max-width: initial;
}
.ContentCarousel .carousel-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  top: 10%;
}
.ContentCarousel .carousel-caption h2 {
  font-weight: 700;
  font-size: 40px;
  color: yellow;
  text-shadow: yellow 1px 0 10px;
}
.ContentCarousel .carousel-caption h2.color-black {
  color: black;
}
.ContentCarousel .carousel-caption h2.color-white {
  color: white;
}
.ContentCarousel .carousel-caption h2.color-blue {
  color: blue;
}
.ContentCarousel .carousel-caption h2.color-green {
  color: green;
}
.ContentCarousel .carousel-caption h2.color-yellow {
  color: yellow;
}
.ContentCarousel .carousel-caption h2.shadow-white {
  text-shadow: white 1px 0 10px;
}
.ContentCarousel .carousel-caption h2.shadow-black {
  text-shadow: black 1px 0 10px;
}
.ContentCarousel .carousel-caption h2.shadow-blue {
  text-shadow: blue 1px 0 10px;
}
.ContentCarousel .carousel-caption h2.shadow-green {
  text-shadow: green 1px 0 10px;
}
.ContentCarousel .carousel-caption h2.shadow-yellow {
  text-shadow: yellow 1px 0 10px;
}
.ContentCarousel .carousel-caption p {
  font-weight: 600;
  margin: 10px 0;
  font-size: 30px;
  color: #fff;
  text-shadow: #fff 1px 0 10px;
}
.ContentCarousel .carousel-caption p.color-black {
  color: black;
}
.ContentCarousel .carousel-caption p.color-white {
  color: white;
}
.ContentCarousel .carousel-caption p.color-blue {
  color: blue;
}
.ContentCarousel .carousel-caption p.color-green {
  color: green;
}
.ContentCarousel .carousel-caption p.color-yellow {
  color: yellow;
}
.ContentCarousel .carousel-caption p.shadow-white {
  text-shadow: white 1px 0 10px;
}
.ContentCarousel .carousel-caption p.shadow-black {
  text-shadow: black 1px 0 10px;
}
.ContentCarousel .carousel-caption p.shadow-blue {
  text-shadow: blue 1px 0 10px;
}
.ContentCarousel .carousel-caption p.shadow-green {
  text-shadow: green 1px 0 10px;
}
.ContentCarousel .carousel-caption p.shadow-yellow {
  text-shadow: yellow 1px 0 10px;
}
.ContentCarousel .carousel-indicators [data-bs-target] {
  background-color: #fff;
}

@media screen and (max-width: 1200px) {
  .ContentCarousel .carousel-control-prev, .ContentCarousel .carousel-control-next {
    display: none;
  }
  .ContentCarousel .carousel-caption h2 {
    font-size: 28px;
  }
  .ContentCarousel .carousel-caption p {
    font-size: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .ContentCarousel .carousel-inner {
    border-radius: 10px;
  }
  .ContentCarousel .carousel-caption h2 {
    font-size: 22px;
  }
  .ContentCarousel .carousel-caption p {
    font-size: 18px;
  }
}
@media screen and (max-width: 900px) {
  .ContentCarousel img {
    border-radius: 0;
    object-fit: cover;
  }
  .ContentCarousel .carousel-item {
    height: 300px;
  }
  .ContentCarousel .carousel-caption {
    top: 15%;
  }
  .ContentCarousel .carousel-caption h2 {
    font-size: 20px;
  }
  .ContentCarousel .carousel-caption p {
    font-size: 16px;
  }
  .ContentCarousel .carousel-indicators {
    top: 70%;
  }
}

