@import "../../../../src/assets/styles/vars";
@import "../../../assets/styles/mixins/components";
@import "../../../assets/styles/mixins/animated-sections";

.Home {
  @include min-component;

  .container-with-bg {
    background: url("/public/images/pipes.png") top no-repeat;
    background-size: cover;
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 60%;
      padding: 35px;
      background: rgba(1,1,1,0.5);
      border-radius: 14px;

      @include bounce;

      h1 {
        margin: 0;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  @include two-block-section;
}

@media screen and (max-width: 900px) {
  .Home {
    .container-with-bg {
      div {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .Home {
    .container-with-bg {
      div {
        h1 {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .Home {
    .container-with-bg {
      div {
        padding: 10px;

        h1 {
          font-size: 14px;
        }
      }
    }
  }
}
