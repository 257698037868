@import "../../../assets/styles/_vars";
@import "../../../assets/styles/mixins/links";

.Footer {
  padding: 30px;
  background: $bg-color;
  color: $secondary-text;

  .top-container {
    display: flex;
    justify-content: space-between;
    padding: 0 350px 15px;
    border-bottom: 1px solid $secondary-text;
    margin-bottom: 15px;

    .container {
      color: $primary-text;
      font: $font-main;

      .title {
        font: 600 18px/21px $font-family;
        color: #fff;
      }

      p .city {
        display: block;
      }

      .mail-link {
        @include link;
      }

      .logo-img {
        width: 70px;
      }
    }
  }
}

@media screen and (min-width: 940px) and (max-width: 1100px) {
  .Footer {
    .top-container {
      padding: 0 250px 15px;
    }
  }
}

@media screen and (max-width: 960px) and (orientation: portrait) {
  .Footer {
    .top-container {
      flex-direction: column;
      padding: 0;
      border-bottom: none;

      .container {
        p .city {
          display: inline;
          margin-left: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .copyright {
      .author {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 570px) and (orientation: portrait) {
  .Footer {
    .top-container {
      text-align: initial;
    }
  }
}
