.Charity {
  min-height: 67vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  overflow-y: auto;
}
.Charity img {
  max-height: 70%;
  max-width: 70%;
  border-radius: 10px;
}
.Charity ol, .Charity ul {
  text-align: start;
}
.Charity .MuiBox-root {
  width: 100%;
  padding: 24px;
}
.Charity .MuiBox-root .MuiButtonBase-root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Charity .tabpanel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 830px;
}
.Charity .tabpanel .MuiBox-root {
  width: 100%;
}
.Charity .images-list {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.Charity .images-list ul {
  margin-bottom: 0;
}
.Charity .images-list ul li .img-contain {
  object-fit: contain;
}

@media screen and (max-width: 760px) {
  .Charity {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 660px) {
  .Charity {
    overflow-x: hidden;
  }
  .Charity .images-list ul {
    display: block;
  }
  .Charity .images-list ul li {
    margin: 4px 0;
  }
}

