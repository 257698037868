@mixin tabs {
  .MuiBox-root {
    width: 100%;
    padding: 24px;

    .MuiButtonBase-root {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tabpanel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 830px;

    .MuiBox-root {
      width: 100%;
    }
  }
}
