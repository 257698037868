@-webkit-keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
}
@-webkit-keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.Quality {
  min-height: 67vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  overflow-y: auto;
  padding: 35px;
  overflow-y: hidden;
}
.Quality img {
  max-height: 70%;
  max-width: 70%;
  border-radius: 10px;
}
.Quality ol, .Quality ul {
  text-align: start;
}
.Quality img {
  max-height: initial;
  max-width: initial;
}
.Quality .section {
  margin: 35px 0;
}
.Quality .section__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
  opacity: 0;
  transition: 1s all ease;
}
.Quality .section__container.active {
  opacity: 1;
}
.Quality .section__container.active .block:first-child {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Quality .section__container.active .block:last-child {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Quality .section__container .block {
  position: relative;
  margin: 0 20px;
  width: 45%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Quality .section__container .block:first-child {
  left: -100px;
}
.Quality .section__container .block:last-child {
  right: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.Quality .section__container .block:last-child h4 {
  text-align: left;
}
.Quality .section__container .block:last-child .btn {
  margin: 15px;
}
.Quality .section__container .block img {
  width: 100%;
  height: 80%;
  border-radius: 10px;
}
.Quality .section:nth-child(2n+1) .section__container {
  flex-direction: row-reverse;
}
@media screen and (max-width: 900px) {
  .Quality .section__container {
    flex-direction: column;
    align-items: center;
  }
  .Quality .section__container .block {
    width: 85%;
    height: auto;
  }
  .Quality .section__container .block:not(:first-child) {
    margin: 15px 0;
  }
  .Quality .section__container .block:last-child {
    padding: 10px 20px;
  }
  .Quality .section:nth-child(2n+1) .section__container {
    flex-direction: column;
  }
}
.Quality h5 {
  text-align: start;
  font-size: 20px;
  margin: 8px 0;
}

@media screen and (max-width: 1000px) {
  .Quality h5 {
    font-size: 18px;
  }
}

