@mixin min-component {
  min-height: $min-height; // when loading component
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin component {
  @include min-component;
  padding: 35px 0;
  overflow-y: auto;

  img {
    max-height: 70%;
    max-width: 70%;

    border-radius: 10px;
  }

  ol, ul {
    text-align: start;
  }
}
