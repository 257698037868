.Footer {
  padding: 30px;
  background: #98A8F8;
  color: #757575;
}
.Footer .top-container {
  display: flex;
  justify-content: space-between;
  padding: 0 350px 15px;
  border-bottom: 1px solid #757575;
  margin-bottom: 15px;
}
.Footer .top-container .container {
  color: #212121;
  font: 400 14px/21px Consolas;
}
.Footer .top-container .container .title {
  font: 600 18px/21px Consolas;
  color: #fff;
}
.Footer .top-container .container p .city {
  display: block;
}
.Footer .top-container .container .mail-link {
  text-decoration: none;
  color: #3F51B5;
  transition: text-decoration 0.3s ease-in-out, color 0.3s ease-in-out;
}
.Footer .top-container .container .mail-link:visited {
  color: #3F51B5;
}
.Footer .top-container .container .mail-link:hover, .Footer .top-container .container .mail-link:active, .Footer .top-container .container .mail-link:focus {
  text-decoration: underline;
}
.Footer .top-container .container .mail-link:hover {
  color: #3D8361;
}
.Footer .top-container .container .mail-link:active, .Footer .top-container .container .mail-link:focus {
  color: #3D8361;
}
.Footer .top-container .container .logo-img {
  width: 70px;
}

@media screen and (min-width: 940px) and (max-width: 1100px) {
  .Footer .top-container {
    padding: 0 250px 15px;
  }
}
@media screen and (max-width: 960px) and (orientation: portrait) {
  .Footer .top-container {
    flex-direction: column;
    padding: 0;
    border-bottom: none;
  }
  .Footer .top-container .container p .city {
    display: inline;
    margin-left: 10px;
  }
  .Footer .top-container .container:not(:last-child) {
    margin-bottom: 10px;
  }
  .Footer .copyright .author {
    display: block;
  }
}
@media screen and (max-width: 570px) and (orientation: portrait) {
  .Footer .top-container {
    text-align: initial;
  }
}

