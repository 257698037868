@import "../../../../src/assets/styles/vars";
@import "../../../assets/styles/mixins/components";

@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.AboutUs {
  @include component;
  padding: 35px;
  overflow-x: hidden;

  p {
    font-size: 18px;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    width: 80%;

    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      font: 500 30px system-ui;
      margin: 0 24px;

      .text {
        margin: 15px;
      }

      .counter {
        font: 800 40px system-ui;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .AboutUs {
    overflow-x: hidden;

    .stats {
      .block {
        font: 500 20px system-ui;

        .counter {
          font: 800 30px system-ui;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .AboutUs {
    overflow-x: hidden;

    .stats {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .block {
        margin: 24px;
      }
    }
  }
}
