@import "../../../../src/assets/styles/vars";
@import "../../../../src/assets/styles/mixins/pdf-reader";

.PDFReader {
  width: 100%; // use full parent width
  padding: 35px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include reader;
}

@media screen and (max-width: 760px) and (orientation: portrait) {
  .PDFReader {
    overflow-x: hidden;
  }
}


@media screen and (min-width: 571px) and (max-width: 760px) and (orientation: portrait) {
  .PDFReader {
    @include reader-760px;
  }
}

@media screen and (min-width: 401px) and (max-width: 570px) and (orientation: portrait) {
  .PDFReader {
    @include reader-570px;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (orientation: portrait) {
  .PDFReader {
    @include reader-400px;
  }
}
