@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins/components";
@import "../../../assets/styles/mixins/animated-sections";
@import "../../../assets/styles/mixins/animations";

.GasStation {
  @include component;
  padding: 35px;

  h3 {
    margin: 20px 0;

    span {
      color: red;
    }
  }

  @include two-block-section;

  .section { // override mixin
    &__container {
      .block {
        height: auto;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .GasStation {
    h3 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 900px) {
  .GasStation {
    h3 {
      font-size: 22px;
    }

    .section { // override mixin
      &__container {
        flex-direction: column-reverse;
      }

      &:nth-child(2n+1) {
        .section__container {
          flex-direction: column-reverse;
        }
      }
    }
  }
}