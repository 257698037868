@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}
.AboutUs {
  min-height: 67vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  overflow-y: auto;
  padding: 35px;
  overflow-x: hidden;
}
.AboutUs img {
  max-height: 70%;
  max-width: 70%;
  border-radius: 10px;
}
.AboutUs ol, .AboutUs ul {
  text-align: start;
}
.AboutUs p {
  font-size: 18px;
}
.AboutUs .stats {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  width: 80%;
}
.AboutUs .stats .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font: 500 30px system-ui;
  margin: 0 24px;
}
.AboutUs .stats .block .text {
  margin: 15px;
}
.AboutUs .stats .block .counter {
  font: 800 40px system-ui;
}

@media screen and (max-width: 960px) {
  .AboutUs {
    overflow-x: hidden;
  }
  .AboutUs .stats .block {
    font: 500 20px system-ui;
  }
  .AboutUs .stats .block .counter {
    font: 800 30px system-ui;
  }
}
@media screen and (max-width: 760px) {
  .AboutUs {
    overflow-x: hidden;
  }
  .AboutUs .stats {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .AboutUs .stats .block {
    margin: 24px;
  }
}

