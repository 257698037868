@-webkit-keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
}
@-webkit-keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.Home {
  min-height: 67vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Home .container-with-bg {
  background: url("/public/images/pipes.png") top no-repeat;
  background-size: cover;
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Home .container-with-bg div {
  width: 60%;
  padding: 35px;
  background: rgba(1, 1, 1, 0.5);
  border-radius: 14px;
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
}
.Home .container-with-bg div h1 {
  margin: 0;
  color: #fff;
  font-weight: 700;
}
.Home .section {
  margin: 35px 0;
}
.Home .section__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
  opacity: 0;
  transition: 1s all ease;
}
.Home .section__container.active {
  opacity: 1;
}
.Home .section__container.active .block:first-child {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Home .section__container.active .block:last-child {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Home .section__container .block {
  position: relative;
  margin: 0 20px;
  width: 45%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Home .section__container .block:first-child {
  left: -100px;
}
.Home .section__container .block:last-child {
  right: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.Home .section__container .block:last-child h4 {
  text-align: left;
}
.Home .section__container .block:last-child .btn {
  margin: 15px;
}
.Home .section__container .block img {
  width: 100%;
  height: 80%;
  border-radius: 10px;
}
.Home .section:nth-child(2n+1) .section__container {
  flex-direction: row-reverse;
}
@media screen and (max-width: 900px) {
  .Home .section__container {
    flex-direction: column;
    align-items: center;
  }
  .Home .section__container .block {
    width: 85%;
    height: auto;
  }
  .Home .section__container .block:not(:first-child) {
    margin: 15px 0;
  }
  .Home .section__container .block:last-child {
    padding: 10px 20px;
  }
  .Home .section:nth-child(2n+1) .section__container {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .Home .container-with-bg div h1 {
    font-size: 22px;
  }
}
@media screen and (max-width: 660px) {
  .Home .container-with-bg div h1 {
    font-weight: 500;
    font-size: 16px;
  }
}
@media screen and (max-width: 500px) {
  .Home .container-with-bg div {
    padding: 10px;
  }
  .Home .container-with-bg div h1 {
    font-size: 14px;
  }
}

