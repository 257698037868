@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins/animations";

@mixin two-block-section {
  .section {
    margin: 35px 0;

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      position: relative;
      opacity: 0;
      transition: 1s all ease;

      &.active { // animation on scroll
        opacity: 1;

        @include block-with-description;
      }

      .block {
        position: relative;
        margin: 0 20px;
        width: 45%;
        height: $section-height;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
          left: -100px;
        }

        &:last-child {
          right: -100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;

          h4 {
            text-align: left;
          }

          .btn {
            margin: 15px;
          }
        }

        img {
          width: 100%;
          height: 80%;
          border-radius: 10px;
        }
      }
    }

    &:nth-child(2n+1) {
      .section__container {
        flex-direction: row-reverse;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .section {
      &__container {
        flex-direction: column;
        align-items: center;

        .block {
          width: 85%;
          height: auto;

          &:not(:first-child) {
            margin: 15px 0;
          }

          &:last-child {
            padding: 10px 20px;
          }
        }
      }

      &:nth-child(2n+1) {
        .section__container {
          flex-direction: column;
        }
      }
    }
  }
}