@import "../../../../src/assets/styles/vars";

.ContactUs {
  min-height: $min-height; // when loading component
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $light-aqua;

  .form {
    margin: 20px;

    &-title {
      margin: 0 5px;
    }

    &__group {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin: 10px 0;
      padding: 15px;
      width: $form-width;

      .field {
        &:active, &:focus, &:focus-visible {
          outline: none;
          border-bottom: 1px solid $hover-color;
        }

        &.textarea {
          resize: none;
        }
      }

      .form-control {
        width: 100%;
        padding: 10px;
        background: none;
        border: none;
        border-bottom: 1px solid $light-blue;

        &.is-invalid {
          border-bottom: 1px solid red;
        }
      }

      .text-muted {
        display: block;
      }

      .invalid-feedback {
        position: absolute;
        color: red;
        top: 55px;
        left: 25px;
        font: 400 12px/18px $font-family;

        &.textarea-feedback {
          top: 105px;
        }
      }
    }

    .submit-btn {
      width: 100px;
      height: 45px;
      padding: 10px 15px;
      border-radius: 14px;
      cursor: pointer;
      font: 500 14px/21px $font-family;
    }
  }
}

.modal {
  padding: 30px;
  border-radius: 18px;

  &-header {
    display: flex;
    font-size: 20px;
  }

  &-title {
    margin: auto;
  }

  &-body {
    margin: 15px 0;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin: 0 10px;
      padding: 5px 10px;
      border-radius: $border;
    }
  }
}

@media screen and (max-width: 960px) and (orientation: portrait) {
  .ContactUs {
    .form {
      margin: 20px;

      &-title {
        width: 90%;
      }

      &__group {
        width: $form-width-sm;
      }
    }
  }
}
