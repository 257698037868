// component
$min-height: 67vh;

// colors
$dark-primary-color: #303F9F;
$light-primary-color: #C5CAE9;
$primary-color: #3F51B5;
$bg-color: #98A8F8;
$primary-text: #212121;
$secondary-text: #757575;
$light-aqua: #EBF4F5;
$light-blue: #AED2D8;

// effects
$hover-color: #3D8361;
$active-color: #425F57;
$active-navlink-color: #432C7A;
$border: 8px;

// font
$font-family: Consolas;
$font-main: 400 14px/21px $font-family;

// contact-us form
$form-width: 500px;
$form-width-sm: 300px;

// home section
$section-height: 800px;
$horiz-slide-anim: 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
$bounce-in-top: 1.1s both;
$slide-to-bottom: 0.4s normal both;
