@import "../../../assets/styles/vars";

@mixin colors {
  &.color {
    &-black {
      color: black;
    }
    &-white {
      color: white;
    }
    &-blue {
      color: blue;
    }
    &-green {
      color: green;
    }
    &-yellow {
      color: yellow;
    }
  }

  &.shadow {
    &-white {
      text-shadow: white 1px 0 10px;
    }
    &-black {
      text-shadow: black 1px 0 10px;
    }
    &-blue {
      text-shadow: blue 1px 0 10px;
    }
    &-green {
      text-shadow: green 1px 0 10px;
    }
    &-yellow {
      text-shadow: yellow 1px 0 10px;
    }
  }
}

.ContentCarousel {
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin: 20px 0;

  img {
    height: auto;
    max-height: initial;
    width: auto;
    max-width: initial;
  }

  .carousel {
    &-caption {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      top: 10%;

      h2 {
        font-weight: 700;
        font-size: 40px;
        color: yellow;
        text-shadow: yellow 1px 0 10px;

        @include colors;
      }

      p {
        font-weight: 600;
        margin: 10px 0;
        font-size: 30px;
        color: #fff;
        text-shadow: #fff 1px 0 10px;

        @include colors;
      }
    }

    &-indicators {
      [data-bs-target] {
        background-color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .ContentCarousel {
    .carousel {
      &-control {
        &-prev,
        &-next {
          display: none;
        }
      }

      &-caption {
        h2 {
          font-size: 28px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .ContentCarousel {
    .carousel {
      &-inner {
        border-radius: 10px;
      }

      &-caption {
        h2 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .ContentCarousel {
    img {
      border-radius: 0;
      object-fit: cover;
    }

    .carousel {
      &-item {
        height: 300px;
      }

      &-caption {
        top: 15%;

        h2 {
          font-size: 20px;
        }

        p {
          font-size: 16px;
        }
      }

      &-indicators {
        top: 70%;
      }
    }
  }
}