@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins/components";
@import "../../../assets/styles/mixins/animated-sections";

.Quality {
  @include component;
  padding: 35px;
  overflow-y: hidden;

  img {
    max-height: initial;
    max-width: initial;
  }

  @include two-block-section;

  h5 {
    text-align: start;
    font-size: 20px;
    margin: 8px 0;
  }
}

@media screen and (max-width: 1000px) {
  .Quality {
    h5 {
      font-size: 18px;
    }
  }
}
