.PDFReader {
  width: 100%;
  padding: 35px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.PDFReader .document {
  -webkit-box-shadow: 0 0 32px 1px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0 0 32px 1px rgba(0, 0, 0, 0.63);
  box-shadow: 0 0 32px 1px rgba(0, 0, 0, 0.63);
}
.PDFReader .btn-block {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.PDFReader .btn-block p {
  margin-bottom: 0;
}
.PDFReader .btn-block .btn {
  cursor: pointer;
  margin: 0 10px;
  padding: 5px 10px;
  font: 400 16px/21px Consolas;
  border: 1px solid #000;
  border-radius: 50%;
  background: none;
  color: #000;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.PDFReader .btn-block .btn svg {
  height: 18px;
  width: 18px;
}
.PDFReader .btn-block .btn:disabled {
  pointer-events: none;
  border: 1px solid #C5CAE9;
  color: #C5CAE9;
}
.PDFReader .btn-block .btn:hover {
  color: #fff;
  background: #3D8361;
}
.PDFReader .btn-block .btn:active {
  background: #425F57;
}

@media screen and (max-width: 760px) and (orientation: portrait) {
  .PDFReader {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 571px) and (max-width: 760px) and (orientation: portrait) {
  .PDFReader .react-pdf__Page__canvas {
    width: 520px !important;
    height: auto !important;
  }
}
@media screen and (min-width: 401px) and (max-width: 570px) and (orientation: portrait) {
  .PDFReader .react-pdf__Page__canvas {
    width: 380px !important;
    height: auto !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 400px) and (orientation: portrait) {
  .PDFReader .react-pdf__Page__canvas {
    width: 300px !important;
    height: auto !important;
  }
}

