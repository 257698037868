@mixin block-with-description {
  .block {
    &:first-child {
      -webkit-animation: slide-right $horiz-slide-anim;
      animation: slide-right $horiz-slide-anim;
    }

    &:last-child {
      -webkit-animation: slide-left $horiz-slide-anim;
      animation: slide-left $horiz-slide-anim;
    }
  }
}

@mixin bounce {
  -webkit-animation: bounce-in-top $bounce-in-top;
  animation: bounce-in-top $bounce-in-top;
}

// KEYFRAMES

@-webkit-keyframes slide-right {
  0% { transform: translateX(0); }
  100% { transform: translateX(100px); }
}
@keyframes slide-right {
  0% { transform: translateX(0); }
  100% { transform: translateX(100px); }
}
@-webkit-keyframes slide-left {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100px); }
}
@keyframes slide-left {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100px); }
}

@-webkit-keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}