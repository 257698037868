@import "../../../../src/assets/styles/vars";
@import "../../../assets/styles/mixins/components";
@import "../../../assets/styles/mixins/tabs";

.Charity {
  @include component;
  @include tabs;

  .images-list {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    ul {
      margin-bottom: 0;

      li {
        .img-contain {
          object-fit: contain;
        }
      }
    }
  }
}


@media screen and (max-width: 760px) {
  .Charity {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 660px) {
  .Charity {
    overflow-x: hidden;

    .images-list {
      ul {
        display: block;

        li {
          margin: 4px 0;
        }
      }
    }
  }
}
