.Header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 10px 0 10px 20px;
  background: #3D8361;
  transition: position 0.3s ease-in-out;
}
.Header.fixed .navbar-logo {
  max-width: 200px;
}
.Header.fixed .navbar-container {
  margin-right: 10%;
  margin-top: 12px;
}
.Header .navbar-logo {
  display: flex;
  margin-left: 0;
}
.Header .navbar-logo .menu-link .logo-img {
  height: 80px;
}
.Header .navbar-nav .block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Header .navbar-nav .block.img-block {
  margin: 0 10px;
}
.Header .navbar-nav .block.img-block .login-img {
  filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
  height: 50px;
  cursor: pointer;
}
.Header .navbar-nav .block.img-block .login-img:hover {
  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(96%) contrast(104%);
}
.Header .navbar-nav .block:not(.no-border)::after {
  content: "";
  bottom: 0;
  display: inline-block;
  position: absolute;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width 0.45s ease-in-out;
}
.Header .navbar-nav .block:not(.no-border):hover::after {
  width: 100%;
}
.Header .navbar-nav .block .menu-link {
  position: relative;
  text-decoration: none;
  margin: 0 30px;
  padding: 10px 0;
  font: 600 14px/21px Consolas;
}
.Header .navbar-nav .block .menu-link:not(.btn-like, .logo) {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .navbar-nav .block .menu-link.btn-like {
  width: 130px;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  box-shadow: inset 0 0 0 1px #fff;
  transition: box-shadow 0.25s ease, color 0.25s ease;
  transition-duration: 0.5s;
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}
.Header .navbar-nav .block .menu-link.btn-like:hover {
  color: #3D8361 !important;
  box-shadow: inset 0 0 0 40px #fff;
}
.Header .navbar-nav .block .menu-link.btn-like:not(:hover):visited {
  color: #3D8361;
}
.Header .navbar-nav .block .menu-link:visited {
  color: #fff;
}

.navbar-toggler {
  border: none;
  /* hide close-icon when burger is shown & vice versa */
  /* **** */
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  transition: background-image 0.3s ease-in-out;
}
.navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler .close-icon {
  color: #fff;
  transition: color 0.3s ease-in-out;
}
.navbar-toggler .close-icon:hover {
  color: #000;
}
.navbar-toggler.collapsed .close-icon {
  display: none;
}
.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}

@-webkit-keyframes slide-bottom {
  0% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(0);
  }
}
.navbar-collapse {
  display: none;
  height: auto;
  transition: all 0.3s ease-in-out;
}
.navbar-collapse.show {
  display: inline;
  height: 100vh;
}
.navbar-collapse.show .navbar-nav {
  position: fixed;
  left: 10%;
  top: 105px;
  width: 80%;
  -webkit-animation: slide-bottom 0.4s normal both;
  animation: slide-bottom 0.4s normal both;
}
.navbar-collapse.show .navbar-nav .block {
  margin: 10px 0;
}
.navbar-collapse.show .navbar-nav .block .menu-link {
  margin: 0 auto;
}
.navbar-collapse.show .navbar-nav .block .menu-link.btn-like {
  width: 100%;
}

@-webkit-keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.modal-body-block {
  margin: 20px 10px;
}
.modal-body-block label {
  margin-left: 10px;
}
.modal-input {
  display: flex;
  align-items: center;
  width: 95%;
}
.modal-input input {
  width: 100%;
  border: 1px solid #AED2D8;
  border-radius: 8px;
  padding: 10px;
}
.modal-input input:active, .modal-input input:focus, .modal-input input:focus-visible {
  border: 1px solid #425F57;
}
.modal-input .show-pass-btn {
  position: absolute;
  right: 65px;
  height: 24px;
  cursor: pointer;
  filter: invert(56%) sepia(0%) saturate(293%) hue-rotate(135deg) brightness(89%) contrast(88%);
}
.modal-input .show-pass-btn.show {
  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(96%) contrast(104%);
}

@media screen and (max-height: 750px) and (orientation: portrait) {
  .navbar-collapse.show .navbar-nav {
    top: 65px;
  }
  .navbar-collapse.show .navbar-nav .block {
    margin: 2px 0;
  }
}

