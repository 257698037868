@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins/components";
@import "../../../assets/styles/mixins/animated-sections";

.Sales {
  @include component;
  padding: 35px;

  img {
    max-height: initial;
    max-width: initial;
  }

  @include two-block-section;

  h5 {
    text-align: start;
    margin: 20px 0;
  }
}