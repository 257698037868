@import "../../../../src/assets/styles/vars";

.Header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 10px 0 10px 20px;
  background: $hover-color;
  transition: position .3s ease-in-out;

  &.fixed { // when burger menu is open
    .navbar {
      &-logo {
        max-width: 200px;
      }

      &-container {
        margin-right: 10%;
        margin-top: 12px;
      }
    }
  }

  .navbar {
    &-logo {
      display: flex;
      margin-left: 0;

      .menu-link .logo-img {
        height: 80px;
      }
    }

    &-nav {
      .block {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.img-block {
          margin: 0 10px;

          .login-img {
            filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
            height: 50px;
            cursor: pointer;

            &:hover {
              filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(96%) contrast(104%);
            }
          }
        }

        &:not(.no-border) {
          &::after {
            content: '';
            bottom: 0;
            display: inline-block;
            position: absolute;
            width: 0;
            height: 2px;
            background: #fff;
            transition: width .45s ease-in-out;
          }

          &:hover::after {
            width: 100%;
          }
        }

        .menu-link {
          position: relative;
          text-decoration: none;
          margin: 0 30px;
          padding: 10px 0;
          font: 600 14px/21px $font-family;

          &:not(.btn-like, .logo) {
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.btn-like {
            width: 130px;
            padding: 10px;
            border-radius: $border;
            color: #fff;
            box-shadow: inset 0 0 0 1px #fff;
            transition: box-shadow 0.25s ease, color 0.25s ease;
            transition-duration: 0.5s;
            -webkit-animation: jello-horizontal 0.9s both;
            animation: jello-horizontal 0.9s both;

            &:hover {
              color: $hover-color !important;
              box-shadow: inset 0 0 0 40px #fff;
            }

            &:not(:hover):visited {
              color: $hover-color;
            }
          }

          &:visited {
            color: #fff;
          }
        }
      }
    }
  }
}

// overriding navbar default styles

.navbar-toggler {
  border: none;

  &:focus {
    box-shadow: none;
  }

  &-icon { // color - stroke='rgba(255, 255, 255, 1)'
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    transition: background-image .3s ease-in-out;
  }

  &:hover {
    .navbar-toggler-icon { // color - stroke='rgba(0, 0, 0, 1)'
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .close-icon {
    color: #fff;
    transition: color .3s ease-in-out;

    &:hover {
      color: #000;
    }
  }

  /* hide close-icon when burger is shown & vice versa */
  &.collapsed .close-icon {
    display: none;
  }

  &:not(.collapsed) .navbar-toggler-icon {
    display: inline;
  }
  /* **** */
}

@-webkit-keyframes slide-bottom {
  0% { transform: translateY(-150px); }
  100% { transform: translateY(0); }
}
@keyframes slide-bottom {
  0% { transform: translateY(-150px);}
  100% { transform: translateY(0);}
}

.navbar-collapse {
  display: none;
  height: auto;
  transition: all .3s ease-in-out;

  &.show {
    display: inline;
    height: 100vh;

    .navbar-nav {
      position: fixed;
      left: 10%;
      top: 105px;
      width: 80%;
      -webkit-animation: slide-bottom $slide-to-bottom;
      animation: slide-bottom $slide-to-bottom;

      .block {
        margin: 10px 0;

        .menu-link {
          margin: 0 auto;

          &.btn-like {
            width: 100%;
          }
        }
      }
    }
  }
}

@-webkit-keyframes jello-horizontal {
  0% { transform: scale3d(1, 1, 1); }
  30% { transform: scale3d(1.25, 0.75, 1); }
  40% { transform: scale3d(0.75, 1.25, 1); }
  50% { transform: scale3d(1.15, 0.85, 1); }
  65% { transform: scale3d(0.95, 1.05, 1); }
  75% { transform: scale3d(1.05, 0.95, 1); }
  100% { transform: scale3d(1, 1, 1); }
}
@keyframes jello-horizontal {
  0% { transform: scale3d(1, 1, 1); }
  30% { transform: scale3d(1.25, 0.75, 1); }
  40% { transform: scale3d(0.75, 1.25, 1); }
  50% { transform: scale3d(1.15, 0.85, 1); }
  65% { transform: scale3d(0.95, 1.05, 1); }
  75% { transform: scale3d(1.05, 0.95, 1); }
  100% { transform: scale3d(1, 1, 1); }
}

// login modal
.modal {
  &-body-block {
    margin: 20px 10px;
    label {
      margin-left: 10px;
    }
  }

  &-input {
    display: flex;
    align-items: center;
    width: 95%;

    input {
      width: 100%;
      border: 1px solid $light-blue;
      border-radius: $border;
      padding: 10px;

      &:active, &:focus, &:focus-visible {
        border: 1px solid $active-color;
      }
    }

    .show-pass-btn {
      position: absolute;
      right: 65px;
      height: 24px;
      cursor: pointer;
      filter: invert(56%) sepia(0%) saturate(293%) hue-rotate(135deg) brightness(89%) contrast(88%);

      &.show {
        filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(96%) contrast(104%);
      }
    }
  }
}

@media screen and (max-height: 750px) and (orientation: portrait) {
  .navbar-collapse {
    &.show {
      .navbar-nav {
        top: 65px;

        .block {
          margin: 2px 0;
        }
      }
    }
  }
}
