@import "../../../../src/assets/styles/vars";

.popup-container {
  position: absolute;
  left: -180px;
  top: -185px;
  padding: 20px;
  background: #fff;
  width: 450px;
  height: 170px;
  border-radius: $border;
  -webkit-box-shadow: 2px 4px 23px 7px rgba(0,0,0,0.53);
  -moz-box-shadow: 2px 4px 23px 7px rgba(0,0,0,0.53);
  box-shadow: 2px 4px 23px 7px rgba(0,0,0,0.53);

  &::after {
    content: '';
    position: absolute;
    top: 170px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
  }

  &--body {
    margin-bottom: 20px;
  }

  &--footer {
    display: flex;
    flex-direction: row-reverse;

    .btn {
      padding: 6px 12px;

      &-cancel {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 510px) and (orientation: portrait) {
  .popup-container {
    width: 300px;
    height: 210px;
    top: -225px;
    left: -115px;

    &::after {
      top: 210px;
    }
  }
}
