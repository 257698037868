@mixin reader {
  .document {
    -webkit-box-shadow: 0 0 32px 1px rgba(0,0,0,0.63);
    -moz-box-shadow: 0 0 32px 1px rgba(0,0,0,0.63);
    box-shadow: 0 0 32px 1px rgba(0,0,0,0.63);
  }

  .btn-block {
    display: flex;
    align-items: center;
    margin-top: 20px;

    p {
      margin-bottom: 0;
    }

    .btn {
      cursor: pointer;
      margin: 0 10px;
      padding: 5px 10px;
      font: 400 16px/21px $font-family;
      border: 1px solid #000;
      border-radius: 50%;
      background: none;
      color: #000;
      transition: background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

      svg {
        height: 18px;
        width: 18px;
      }

      &:disabled {
        pointer-events: none;
        border: 1px solid $light-primary-color;
        color: $light-primary-color;
      }

      &:hover {
        color: #fff;
        background: $hover-color;
      }

      &:active {
        background: $active-color;
      }
    }
  }
}

@mixin reader-760px {
  .react-pdf {
    &__Page {
      &__canvas {
        width: 520px !important;
        height: auto !important;
      }
    }
  }
}

@mixin reader-570px {
  .react-pdf {
    &__Page {
      &__canvas {
        width: 380px !important;
        height: auto !important;
      }
    }
  }
}

@mixin reader-400px {
  .react-pdf {
    &__Page {
      &__canvas {
        width: 300px !important;
        height: auto !important;
      }
    }
  }
}
