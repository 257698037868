@import "../../../assets/styles/_vars";

@mixin link {
  text-decoration: none;
  color: $primary-color;
  transition: text-decoration 0.3s ease-in-out,
              color 0.3s ease-in-out;

  &:visited {
    color: $primary-color;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &:hover {
    color: $hover-color;
  }

  &:active,
  &:focus {
    color: $hover-color;
  }
}

